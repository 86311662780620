import React, { useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { Form as FormCrud, useCrudForm } from "../../components/Crud";
import conf from "../../config/crud/reportFilters";
import { dateTimeFormat } from "../../config/constants";

const ReportFilter = ({ onFilter, showFields = [] }) => {
  const propsForm = useCrudForm({ conf, key: null });
  const onSubmit = (values) => {
    const filters = { ...values };
    if (values.range.length === 2) {
      filters.fromDate = moment(values.range[0]).format(dateTimeFormat);
      filters.untilDate = moment(values.range[1]).format(dateTimeFormat);
    }
    onFilter(filters);
  };
  useEffect(() => {
    propsForm.fields.forEach((field) => {
      field.hide = showFields.indexOf(field.key) === -1;
    });
  }, [propsForm.fields]);

  return (
    <FormCrud
      key={Date.now().valueOf()}
      hideTitle
      layout="vertical"
      verticalLabel
      subTitle={"Filtros"}
      rowKey={conf.keyName}
      submitButtonText="Consultar"
      buttonsAlign={"center"}
      {...propsForm}
      onSubmit={onSubmit}
    />
  );
};

ReportFilter.propTypes = {
  onFilter: PropTypes.func,
};

export default ReportFilter;
