import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { useChargerSocket } from "../components/Charger/hooks";
import { NOTIFICATION_TYPE, ROUTES } from "../config/constants";

export const SocketContext = createContext({});

export const SocketProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const openNotification = ({
    title = `Nueva notificación`,
    message,
    type = "info",
  }) => {
    api[type]({
      message: title,
      description: message,
      placement: "topRight",
    });
  };
  const socketHandler = (data) => {
    const { transactionId } = data;

    switch (data.status) {
      case "ON":
        addNotification(
          NOTIFICATION_TYPE.CHARGER_ON,
          `Cargador encendido, trnsactinoID: ${transactionId}`,
          () =>
            openNotification({
              title: "Cargador Encendido",
              message: `Se ha iniciado una carga con el ID de transacción ${transactionId}`,
            }),
          `${ROUTES.userChargerLog}/${transactionId}`
        );
        break;
      case "OFF_ADMIN":
      case "CHARGING_ENDED_BY_OCLOUD":
      case "OFF":
        addNotification(
          NOTIFICATION_TYPE.CHARGER_OFF,
          `Cargador apagado, trnsactinoID: ${transactionId}`,
          () =>
            openNotification({
              title: "Cargador Apagado",
              message: `Se ha desconectado un cargador; con el ID de transacción ${transactionId}`,
            }),
          `${ROUTES.userChargerLog}/${transactionId}`
        );
        break;
      case "RPC_OFF":
        addNotification(
          NOTIFICATION_TYPE.RPC_DESCONNECTED,
          `RPC desconectada`,
          () =>
            openNotification({
              title: "RPC desconectada",
              message: `Se ha una o más RPC`,
            }),
          `${ROUTES.rpc}`
        );
        // showWarning(
        //   "¡La RPC se ha desconectado, Todos sus cargadores se han desactivado!"
        // );
        break;
      //   default:
      //     showError(data.error);
      //     break;
    }
  };

  const addNotification = (type, title, callback, redirect) => {
    const key = new Date().valueOf();
    dispatch.notifications.show({
      notification: {
        key,
        type,
        title,
        redirect,
      },
      callback,
    });
  };

  const {
    activeChargerRequest,
    disableChargerRequest,
    turnOffChargingByChargerDisabed,
  } = useChargerSocket({
    observer: socketHandler,
  });

  return (
    <SocketContext.Provider
      value={{
        //Methods
        activeChargerRequest,
        disableChargerRequest,
        turnOffChargingByChargerDisabed,
        openNotification,
      }}
    >
      <>
        {contextHolder}
        {children}
      </>
    </SocketContext.Provider>
  );
};
