import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { Badge, Avatar, Dropdown, Space, Typography, Empty } from "antd";
import {
  NotificationFilled,
  AlertFilled,
  ThunderboltTwoTone,
  ThunderboltOutlined,
} from "@ant-design/icons";

import { dateToString } from "../../utils/general";
import { NOTIFICATION_TYPE } from "../../config/constants";

const iconByType = {
  [NOTIFICATION_TYPE.CHARGER_ON]: <ThunderboltTwoTone />,
  [NOTIFICATION_TYPE.CHARGER_OFF]: <ThunderboltOutlined />,
  [NOTIFICATION_TYPE.RPC_DESCONNECTED]: <AlertFilled />,
  [NOTIFICATION_TYPE.NEW_PQRS]: <AlertFilled />,
  [NOTIFICATION_TYPE.CHARGER_DESCONNECTED]: <AlertFilled />,
  [NOTIFICATION_TYPE.STATION_DISABLED]: <AlertFilled />,
};

const { Text } = Typography;

const emptyItem = [
  {
    label: <Empty description="No hay notificaciones" />,
    key: "-1",
  },
];

const Notifications = ({ notifications, remove }) => {
  const navigate = useNavigate();
  return (
    <Dropdown
      trigger={["click"]}
      menu={{
        items:
          notifications.length === 0
            ? emptyItem
            : notifications.map((x) => ({
                key: x.key,
                icon: iconByType[x.type],
                label: (
                  <Space
                    direction="vertical"
                    onClick={() => remove(x.key, () => navigate(x.redirect))}
                  >
                    {x.title}
                    <Text type="secondary">{dateToString(x.date)}</Text>
                  </Space>
                ),
              })),
      }}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Badge count={notifications.length}>
          <Avatar shape="circle" size="large" icon={<NotificationFilled />} />
        </Badge>
      </a>
    </Dropdown>
  );
};

Notifications.propTypes = {};

const mapState = (state) => ({
  notifications: state.notifications.list,
});
const mapDispatch = (dispatch) => ({
  remove: (key, callback) => dispatch.notifications.remove({ key, callback }),
});

export default connect(mapState, mapDispatch)(Notifications);
