import React from "react";
import { Layout, Row, Col } from "antd";
import { HomeOutlined, HomeFilled } from "@ant-design/icons";
import { NotificationCenter, HeaderUser } from ".";

const Header = ({ pathname, isCollapsed, showDrawer, drawerVisible }) => (
  <Layout.Header className="custom-header">
    <Row type="flex" align="middle" justify="space-between">
      <Col span={18}>
        {isCollapsed ? (
          <HomeFilled
            color="white"
            onClick={showDrawer}
            className="custom-header-toggle-icon"
          />
        ) : (
          <HomeOutlined
            color="white"
            onClick={showDrawer}
            className="custom-header-toggle-icon"
          />
        )}
      </Col>
      <Col>
        <NotificationCenter />
      </Col>
      <Col order={2} className="custom-align-right">
        <Row type="flex" align="middle" justify="end">
          <HeaderUser />
          {/* <MenuHeader isCollapse={isCollapsed} pathname={pathname} /> */}
        </Row>
      </Col>
    </Row>
  </Layout.Header>
);

Header.defaultProps = {
  isCollapsed: false,
  drawerVisible: false,
};

export default Header;
