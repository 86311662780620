import { getPersistor } from "@rematch/persist";

import Instance from "../services/instance";
import { authenticateService, validateTokenService } from "../services/auth";
import { STATUS_CODE } from "../config/constants";
import { showError } from "../utils/message";

const defaultState = {
  accessToken: null,
  refreshToken: null,
  user: null,
  permissions: null,
  isAuthenticated: false,
};

const auth = {
  state: defaultState,
  reducers: {
    SET_AUTH: (state, payload) => ({
      ...state,
      ...payload,
      isAuthenticated: !!payload.accessToken,
    }),
  },
  effects: dispatch => ({
    async authentication({ credentials, callback }) {
      try {        
        const response = await authenticateService(
          credentials.username,
          credentials.password,
          "BACKOFFICE-ACCESS"
        );
        const auth = response.data;
        if (auth.statusCode !== STATUS_CODE.SUCCESS) {
          throw new Error(auth.message);
        }

        const {
          user,
          tokens: { accessToken, refreshToken },
          permissions,
          fullname,
        } = auth.data;
        this.SET_AUTH({
          user,
          accessToken,
          refreshToken,
          permissions,
          fullname,
        });
        Instance.setToken(accessToken);
        dispatch.config.loadMenu();
        callback && callback();
      } catch (error) {
        showError(error.message, 10);
        Instance.removeToken();
        this.SET_AUTH(defaultState);
      }
    },
    async validateToken({}) {
      const response = await validateTokenService();
      const auth = response.data;
      if (auth.statusCode !== STATUS_CODE.SUCCESS) {
        throw new Error(auth.message);
      }
    },
    logout({ onLogout }) {
      const persistor = getPersistor();
      persistor.purge();
      Instance.removeToken();
      this.SET_AUTH(defaultState);
      dispatch.config.removeMenu();
      onLogout && onLogout();
    },
  }),
};

export default auth;
