import React, { useState, useContext } from "react";
import { Card, Collapse, Space, Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";

import { ReportFilter } from "../components/Report";
import { HomeCards } from "../components/Home";

import { List as ListCrud, useCrudList } from "../components/Crud";
import config from "../config/crud/userChargerReport";
import { downloadUserReport } from "../services/charger";
import { GeneralStatistics } from "../components/Home";

const Home = ({}) => {
  const [filters, setFilters] = useState({});
  const { columns, dataSource, loading, reload } = useCrudList(config);

  const events = {
    exportReport: () => {
      downloadUserReport();
    },
    onFilter: (values) => {
      setFilters(values);
      reload(values);
    },
  };

  return (
    <div>
      <GeneralStatistics />
      <Collapse style={{ margin: "16px 0px" }}>
        <Collapse.Panel header="Filtros">
          <ReportFilter
            onFilter={events.onFilter}
            loading={loading}
            showFields={["stationId", "relayId", "range", "userId"]}
          />
        </Collapse.Panel>
      </Collapse>

      <HomeCards dataSource={dataSource} loading={loading} filters={filters} />
      <Card hoverable>
        <ListCrud
          subTitle={"Consolidado de conexiones por usuario"}
          columns={columns}
          dataSource={dataSource}
          addButtons={[
            {
              text: `Exportar a excel`,
              icon: <ExportOutlined />,
              onClick: events.exportReport,
            },
          ]}
          loading={loading}
          pagination={{
            pageZise: 20,
            showQuickJumper: true,
            showSizeChanger: true,
          }}
        />
      </Card>
    </div>
  );
};

export default Home;
