import moment from "moment";
import { dateTimeFormat } from "../config/constants";

/***
 * NotificationObject =>
 * key: unique
 * type: NOTIFICATION_TYPE
 * title: string
 * onClick: () => {}, removeFromList
 * date: string
 */
const notifications = {
  state: {
    list: [],
  },
  reducers: {
    ADD: (state, payload) => ({
      ...state,
      list: [payload, ...state.list],
    }),
    REMOVE: (state, payload) => ({
      ...state,
      list: state.list.filter((x) => x.key !== payload),
    }),
  },
  effects: {
    /**
     * Agrega la notificación al conponente
     * @param {notification: {key: string, type: NOTIFICATION_TYPE, title: string, onClick: () => {}, date: string }, callback: () => {}} param0
     */
    show({ notification, callback = () => {} }) {
      this.ADD({
        ...notification,
        date: moment().format(dateTimeFormat),
      });
      callback();
    },
    /**
     * Elimina una notificación por id
     * @param {key: string, callback: () => {}} param0 parametros
     */
    remove({ key, callback = () => {} }) {
      this.REMOVE(key);
      callback();
    },
  },
};

export default notifications;
