import { init } from "@rematch/core";
import persist from "@rematch/persist";
import loading from "@rematch/loading";
import storage from "redux-persist/lib/storage";
import instance from "../services/instance";

import * as models from "../models";

const store = init({
  models,
  plugins: [
    persist({
      whitelist: ["auth", "config", "rpc", "charger", "notifications"],
      key: "-!-persist-key-",
      storage,
      throttle: 500,
      version: 1,
    }),
    loading({}),
  ],
});

const state = store.getState();
const unsubscribe = store.subscribe(() => {
  const newAuth = store.getState().auth;
  if (state.auth !== newAuth) {
    instance.setToken(newAuth.accessToken);
  }
});

export default store;
